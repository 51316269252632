<template>
    <div>
        <CCard>
            <CCardHeader>
                <div><h4>PruebaTarheta</h4></div>
        
            </CCardHeader>
            <CCardBody>
                
                <CButton type="submit" class="mb-2 btn-class"  
                    @click="mostrarAgregarNuevoCliente = !mostrarAgregarNuevoCliente">
                    {{mostrarAgregarNuevoCliente?"Ocultar":"Agregar Nuevo Cliente"}}
                </CButton>
                <CRow v-show="mostrarAgregarNuevoCliente">
                    <CCol sm="0" md="2"></CCol>
                    <CCol sm="12" md="8">
                        <CCard accent-color="info">
                            <CCardHeader>
                                <h4>Nuevo Cliente</h4>
                            </CCardHeader>
                            <CCardBody>
                                <CInput
                                    v-model="clienteNuevo.rutCliente"
                                    label="Rut"
                                    type="text"
                                    horizontal
                                    placeholder="12345678-9"
                                />
                                <CInput
                                    v-model="clienteNuevo.nombreCliente"
                                    label="Nombre"
                                    type="text"
                                    horizontal
                                />
                                <CInput
                                    v-model="clienteNuevo.rutaCarpetaBd"
                                    label="ruta Carpeta Bd"
                                    type="text"
                                    horizontal
                                />
                                
                            </CCardBody>
                            <CCardFooter>
                                <CButton type="submit" size="sm" class="float-right btn-class" 
                                    @click="agregarCliente"><!--v-show="userEditados.length>0"  @click="editarUsuarios"-->
                                    <CIcon name="cil-check-circle"/> Crear Cliente
                                </CButton>
                            </CCardFooter>
                        </CCard>
                        
                    </CCol>
                </CRow>
                <a-table
                    :columns="columnasClientes" 
                    :data-source="clientesData"
                    :scroll="{ y: 300 }"
                    class="components-table-demo-nested mt-3" 
                    size="small" 
                    style="overflow:auto;">

                    <slot slot="rutEmpresa" slot-scope="cliente">
                        <div v-show="!cliente.editar">
                            {{cliente.rutEmpresa}}
                        </div>
                         <div v-show="cliente.editar">
                            <CInput
                                style="margin-top:0"
                                v-model="cliente.rutEmpresa"
                                type="text"
                                horizontal
                                placeholder="Rut Cliente"
                            />
                        </div>
                    </slot>
                    <slot slot="nombreCliente" slot-scope="cliente">
                        <div v-show="!cliente.editar">
                            {{cliente.nombreCliente}}
                        </div>
                         <div v-show="cliente.editar">
                            <CInput
                                style="margin-top:0"
                                v-model="cliente.nombreCliente"
                                type="text"
                                horizontal
                                placeholder="Id Unidad"
                            />
                        </div>
                    </slot>
                    <slot slot="rutaCarpetaBd" slot-scope="cliente">
                        <div v-show="!cliente.editar">
                            {{cliente.rutaCarpetaBd}}
                        </div>
                         <div v-show="cliente.editar">
                            <CInput
                                style="margin-top:0"
                                v-model="cliente.rutaCarpetaBd"
                                type="text"
                                horizontal
                                placeholder="Ruta Carpeta Bd"
                            />
                        </div>
                    </slot>
                    <slot slot="idUnidadDrive" slot-scope="cliente">
                        
                        <div v-show="!cliente.editar">
                            {{cliente.idUnidadDrive}}
                        </div>
                        
                        <div v-show="cliente.editar">
                            <CInput
                                style="margin-top:0"
                                v-model="cliente.idUnidadDrive"
                                type="text"
                                horizontal
                                placeholder="Id Unidad Drive"
                            />
                        </div>
                    </slot>
                    <slot slot="fechaCreacion" slot-scope="cliente">
                        {{formatFecha(cliente.fechaCreacion)}}
                        
                    </slot>
                    <slot slot="habilitado" slot-scope="cliente">
                        <div v-show="!cliente.editar">
                            {{cliente.habilitado?"Si":"No"}}
                        </div>
                        <CSwitch
                            v-show="cliente.editar"
                            width="150px"
                            class="mx-1 align-middle mb-2" 
                            color="success" 
                            shape="pill"
                            variant="outline"
                            v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                            :checked="cliente.habilitado"
                            @update:checked="cliente.habilitado = !cliente.habilitado"
                        />
                    </slot>
                    <span
                        slot="acciones"
                        slot-scope="cliente">
                            <!--<CButtonToolbar >
                                <CAlert v-show="record.eliminar" color="danger">
                                    ¿Está seguro?
                                    <CButtonGroup class="mx-1">
                                        <CButton color="success" size="sm"
                                            v-c-tooltip.hover="{content:'Si, Eliminar', placement:'left'}"
                                            @click="eliminarUser(record)">
                                            <CIcon name="cil-check-circle"/>
                                        </CButton>
                                        <CButton color="danger" size="sm"
                                            v-c-tooltip.hover="{content:'No!', placement:'right'}"
                                            @click="record.eliminar = false">
                                            <CIcon name="cil-x-circle"/>
                                        </CButton>
                                    </CButtonGroup>
                                </CAlert>
                                

                                <CButtonGroup class="mx-1" v-show="record.editar && !record.eliminar">
                                    <CButton color="success" size="sm"
                                        v-c-tooltip.hover="{content:'Aceptar Cambios', placement:'left'}"
                                        :disabled="!record.rutValidadoUser 
                                        || !record.mailValidoUser 
                                        || !record.userName.length>0
                                        || !record.nombreUsuario.length>0
                                        || !record.apellidoUsuario.length>0"
                                        @click="aceptarEdicion(record)">
                                        <CIcon name="cil-check-circle"/>
                                    </CButton>
                                    <CButton color="danger" size="sm"
                                        v-c-tooltip.hover="{content:'Cancelar Cambios', placement:'right'}"
                                        @click="record.editar=false; cancelarEdicion(record)">
                                        <CIcon name="cil-x-circle"/>
                                    </CButton>
                                </CButtonGroup>

                                <CButtonGroup size="sm" class="mx-1" v-show="!record.editar && !record.eliminar">
                                    <CButton color="warning" size="sm"
                                    v-c-tooltip.hover="{content:'Editar Usuario', placement:'left'}"
                                    @click="record.editar = true; editarUser(record)">
                                        <CIcon name="cil-pencil"/>
                                    </CButton>
                                    <CButton color="danger" 
                                    v-c-tooltip.hover="{content:'Eliminar Usuario', placement:'right'}"
                                    @click="record.eliminar = true" size="sm" ><CIcon name="cil-x-circle"/>
                                    </CButton>
                                </CButtonGroup>
                                
                            </CButtonToolbar>
                            -->
                            <CButtonToolbar >
                                <CButtonGroup class="mx-1" v-show="cliente.editar">
                                    <CButton color="success" size="sm"
                                        v-c-tooltip.hover="{content:'Aceptar Cambios', placement:'left'}">
                                        <CIcon name="cil-check-circle"/>
                                    </CButton>
                                    <CButton color="danger" size="sm"
                                        v-c-tooltip.hover="{content:'Cancelar Cambios', placement:'right'}"
                                        @click="cliente.editar=false; cancelarEdicion(cliente)">
                                        <CIcon name="cil-x-circle"/>
                                    </CButton>
                                </CButtonGroup>

                                <CButtonGroup size="sm" class="mx-1" v-show="!cliente.editar">
                                    <CButton color="warning" size="sm"
                                    v-c-tooltip.hover="{content:'Editar Cliente', placement:'left'}"
                                    @click="cliente.editar = true; editarCliente(cliente)"
                                    >
                                        <CIcon name="cil-pencil"/>
                                    </CButton>
                                    <CButton color="danger" 
                                    v-c-tooltip.hover="{content:'Eliminar Cliente', placement:'right'}"
                                    @click="eliminarCliente(cliente)"
                                    size="sm" ><CIcon name="cil-x-circle"/>
                                    </CButton>
                                </CButtonGroup>
                            </CButtonToolbar>
                        </span>
                </a-table>
            </CCardBody>
        <CCardFooter>
            
        </CCardFooter>
    </CCard>
    
  </div>
</template>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import cons from "@/const";
    import axios from "axios";
    import Multiselect from "vue-multiselect";
    import qs from "qs";

    import VueModal from "@kouts/vue-modal";
    import "@kouts/vue-modal/dist/vue-modal.css";

    import { freeSet } from "@coreui/icons";


    import { format, formatDistance, formatRelative, subDays } from "date-fns";
    import es from "date-fns/locale/es";
    window.locale = "es";

    export default {
        name: "ClientesAdmin",
        freeSet,
        components: {
            Multiselect,
            Modal: VueModal
        },

        data() {
            return {
                //tokenLogin: JSON.parse(this.$cookie.get("userLogginToken")).tokenUsuario,

                regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

                mostrarAgregarNuevoCliente: false,
                clienteNuevo: {
                    "rutCliente": "",
                    "nombreCliente": "",
                    "rutaCarpetaBd": "",
                    "idUnidadDrive": ""
                },

                clientesData: [],

                columnasClientes: [
                    { title: 'Id', dataIndex: 'idCliente', key: 'idEmpresa', width: '5%', align: 'center'},
                    { title: 'Rut', scopedSlots: { customRender: 'rutEmpresa' }, width: '10%', align: 'left'},
                    { title: 'Nombre Cliente', scopedSlots: { customRender: 'nombreCliente' }, width: '20%', align: 'left'},
                    { title: 'Ruta Bd (Edig)', scopedSlots: { customRender: 'rutaCarpetaBd' }, width: '20%', align: 'left'},
                    { title: 'Id Unidad Google Drive', scopedSlots: { customRender: 'idUnidadDrive' }, width: '20%', align: 'left'},
                    { title: 'Creado',scopedSlots: { customRender: 'fechaCreacion' },width: '10%', align: 'left'},
                    { title: 'Habilitado', scopedSlots: { customRender: 'habilitado' }, width: '10%', align: 'center'},
                    { title: 'Acciones', scopedSlots: { customRender: 'acciones' }, width: '10%', align: 'left'}
                ],

            };
        },
        beforeMount() {
            this.getClientes()
        },

        methods: {

            getClientes: function(){
                var url = cons.port+"://" + cons.ipServer + "/get_clientes_vapp";

                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': "asdasdasd"//`${this.tokenLogin}`
                    }
                }).then(result => {
                    this.clientesData = result.data;
                    
                }, error => {
                        let mensaje = `Problema al obtener Clientes, ${error}`
                })
                .finally(() => {
                });
            },
            agregarCliente: function(){
                 var url = cons.port+"://" + cons.ipServer + "/agregar_cliente/";

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "clienteData": {
                            "rutCliente": this.clienteNuevo.rutCliente,
                            "nombreCliente": this.clienteNuevo.nombreCliente,
                            "rutaCarpetaBd": this.clienteNuevo.rutaCarpetaBd,
                            "idUnidadDrive": this.clienteNuevo.idUnidadDrive
                        }
                    },
                    "headers": {
                        'Authorization': "asdasdasd"//`${this.tokenLogin}`
                    }
                }).then(result => {
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});
                    this.getClientes()
                    
                }, error => {
                        let mensaje = `Problema al obtener Clientes, ${error}`
                        this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                });
            },
            editarCliente: function(cliente){
                cliente.respaldocliente = {
                    'nombreCliente': cliente.nombreCliente,
                    'rutEmpresa': cliente.rutEmpresa,
                    'rutaCarpetaBd': cliente.rutaCarpetaBd,
                    'idUnidadDrive': cliente.idUnidadDrive,
                    'habilitado': cliente.habilitado
                }
                
            },
            cancelarEdicion: function(cliente){
                cliente.nombreCliente = cliente.respaldocliente.nombreCliente
                cliente.rutEmpresa = cliente.respaldocliente.rutEmpresa
                cliente.rutaCarpetaBd = cliente.respaldocliente.rutaCarpetaBd
                cliente.idUnidadDrive = cliente.respaldocliente.idUnidadDrive
                cliente.habilitado = cliente.respaldocliente.habilitado
            },
            eliminarCliente: function(usuario){
                this.$notification.success("User Eliminado", {  timer: 10, position:"bottomRight"});
            },

            formatFecha: function(fecha){
                //return format(new Date(fechaDoc), 'EEE d LLL/yy H:mm:ss', {locale: es})//'dd/MM/yyyy' 'EEE d LLL/yy H:mm:ss'
                if(fecha != null)
                    return formatDistance(new Date(fecha), new Date(), { addSuffix: true, locale: es})//.isSame(moment().clone().startOf('day'), 'd')
                else
                    return "Nunca"
                
            },
        },
    };
</script>

<style scoped>
.form-group{
  margin-top: 1rem;
  margin-bottom: 0;
}
.form-row{
  width: 80%;
}
</style>